import React from 'react';

import PerformanceCurrency from '../../../../common/components/format/performance-currency/PerformanceCurrency';
import { DatalabTableColumnCategory } from '../../constants';
import { maxField, meanField, medianField, minField, sumField } from '../../summaryFields';
import { ColumnCategoryConfig } from '../advanced-filters-config';
import { DatalabTableColumn, dtc } from '../datalabTableColumns';
import { setColumnsCategory } from '../utils';
import { categorizedColumns as categorizedMasterColumns } from './master-columns';

export const columns: DatalabTableColumn[] = [
  dtc.offering_confidential_filing_date,
  dtc.offering_public_filing_date,
  dtc.offering_launch_date,
  dtc.timing_of_launch,
  dtc.offerings_pricing_date,
  dtc.offering_first_trade_date,
  dtc.offering_settlement_date,
  dtc.companies_issuer,
  {
    ...dtc.fund_name,
    renderer: undefined, // renderer would target actual page
  },
  dtc.allocation,
  dtc.ioi_shares,
  dtc.fill_rate,
  dtc.allocation_pct,
  {
    ...dtc.allocation_investment,
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
  },
  dtc.companies_ticker,
  dtc.companies_cik,
  dtc.companies_cusip,
  dtc.issuer_isin,
  dtc.offering_entity_structure,
  dtc.exchange_mic,
  dtc.pricing_instrument_region,
  dtc.pricing_currency_code,
  dtc.country_code,
  dtc.offerings_sector,
  dtc.companies_sub_sector,
  { ...dtc.offerings_type, label: 'Type' },
  dtc.offering_security_type,
  dtc.filing_details_offering_price_local_curr,
  dtc.filing_details_offering_price,
  dtc.filing_details_net_price,
  dtc.offering_size,
  dtc.total_shares_filed_excl_shoe,
  dtc.offering_primary_shares_base_offering,
  dtc.offering_secondary_shares_base_offering,
  dtc.filing_details_pct_secondary_shares,
  dtc.offering_over_allotment_authorized,
  dtc.offering_over_allotment_exercised,
  dtc.filing_details_gross_proceeds_local_curr,
  dtc.filing_details_gross_proceeds,
  dtc.filing_details_gross_proceeds_with_over_allotment_local_curr,
  dtc.filing_details_gross_proceeds_with_over_allotment,
  dtc.initial_gross_proceeds_base,
  dtc.initial_gross_proceeds_base_local_curr,
  dtc.revised_gross_proceeds_base,
  dtc.revised_gross_proceeds_base_local_curr,
  dtc.filing_details_market_cap_at_pricing_local_curr,
  dtc.filing_details_market_cap_at_pricing,
  dtc.filing_details_market_cap_pre_offering,
  dtc.offering_post_offering_shares,
  dtc.offering_pre_offering_shares,
  dtc.filing_details_deal_pct_at_pricing_market_cap,
  dtc.filing_details_deal_pct_market_cap_pre_offering,
  dtc.offerings_pre_offering_adtv,
  dtc.offerings_size_as_multiple_of_adtv,
  dtc.offerings_file_to_offer_discount,
  dtc.offerings_discount_to_last_trade,
  dtc.offering_discount_to_vwap,
  dtc.offerings_discount_to_52wk_high,
  {
    ...dtc.sales_concession,
    summaryConfig: [meanField, medianField, minField, maxField],
  },
  {
    ...dtc.return_open,
    label: 'Return Open',
    summaryConfig: [sumField, meanField, medianField, minField, maxField],
    renderer: v => <PerformanceCurrency value={v} />,
    fixedMinWidth: undefined,
  },
  dtc.return_day1,
  dtc.return_day3,
  dtc.return_day7,
  dtc.offering_gross_spread_pct,
  dtc.offering_gross_spread,
  dtc.offerings_all_in_cost,
  dtc.estimated_fee,
  dtc.offering_initial_registration_value,
  dtc.offering_initial_ipo_range_low_local_curr,
  dtc.offering_initial_ipo_range_low,
  dtc.filing_details_price_vs_range,
  dtc.offering_price_vs_midpoint,
  dtc.offering_pct_change_in_size,
  dtc.offering_revised_ipo_range_low,
  dtc.last_trade_before_filing_local_curr,
  dtc.last_trade_before_filing,
  dtc.last_trade_before_offer_local_curr,
  dtc.last_trade_before_offer,
  dtc.fifty_two_wk_high,
  dtc.re_offer_low_local_curr,
  dtc.re_offer_low,
  dtc.re_offer_high_local_curr,
  dtc.re_offer_high,
  dtc.re_offer_discount_high,
  dtc.re_offer_discount_low,
  dtc.offering_first_day_volume,
  dtc.offering_first_day_turnover,
  dtc.offering_offer_to_open,
  dtc.offering_offer_to_vwap_1day,
  dtc.offering_offer_to_1day,
  dtc.offering_offer_to_3day,
  dtc.offering_offer_to_7day,
  dtc.offering_offer_to_14day,
  dtc.offering_offer_to_30day,
  dtc.offering_offer_to_90day,
  dtc.offering_offer_to_180day,
  dtc.offering_offer_to_one_year,
  dtc.offering_offer_to_prior_quarter,
  dtc.offering_offer_to_current,
  dtc.offering_total_managers,
  dtc.offering_total_bookrunners,
  dtc.offering_total_non_bookrunners,
  dtc.offering_total_pct_to_bookrunners,
  dtc.offering_total_pct_to_non_bookrunners,
  dtc.offering_total_pct_to_left_lead,
  dtc.lock_up_period,
  dtc.lock_up_expiration,
  dtc.lock_up_early_release_date,
  dtc.conditional_lock_up,
  dtc.multiple_lock_ups,
  dtc.left_lead_firm_name,
  dtc.primary_shareholder_name,
  dtc.offering_pre_offering_ownership,
  dtc.offering_pct_pre_offering_ownership,
  dtc.offering_post_offering_ownership,
  dtc.offering_pct_post_offering_ownership,
  dtc.offering_use_of_proceeds,
  dtc.offering_forward_agreement,
  dtc.first_follow_on,
  dtc.split_adjusted_offering_price,
  dtc.carve_out,
  dtc.clean_up_trade,
  dtc.concurrent_offering,
  dtc.dual_listed,
  dtc.egc,
  dtc.synthetic_secondary,
  dtc.up_listing,
  dtc.use_of_proceeds_note,
  dtc.company_repurchase,
  dtc.headquarters,
  dtc.naics,
  dtc.cornerstone_investors,
  dtc.cornerstone_investors_list,
  dtc.cornerstone_investment,
  dtc.cornerstone_investment_type,
  dtc.number_of_cornerstone_investors,
];

export const visibleFields: string[] = [
  dtc.fund_name.field,
  dtc.offerings_pricing_date.field,
  dtc.companies_issuer.field,
  dtc.companies_ticker.field,
  dtc.offerings_sector.field,
  dtc.offerings_type.field,
  dtc.filing_details_offering_price.field,
  dtc.offering_size.field,
  dtc.filing_details_gross_proceeds.field,
  dtc.filing_details_gross_proceeds_with_over_allotment.field,
  dtc.filing_details_market_cap_at_pricing.field,
  dtc.filing_details_deal_pct_at_pricing_market_cap.field,
  dtc.return_open.field,
  dtc.return_day1.field,
  dtc.return_day3.field,
  dtc.return_day7.field,
  dtc.offering_offer_to_open.field,
  dtc.offering_offer_to_1day.field,
  dtc.offering_offer_to_7day.field,
  dtc.offering_offer_to_30day.field,
  dtc.offering_offer_to_180day.field,
  dtc.offering_offer_to_current.field,
  dtc.offering_gross_spread_pct.field,
  dtc.left_lead.field,
  dtc.allocation_pct.field,
  dtc.allocation.field,
  dtc.fill_rate.field,
  dtc.ioi_shares.field,
  dtc.allocation_investment.field,
];

export const staticFields: string[] = [dtc.companies_issuer.field, dtc.companies_ticker.field];

export const categorizedColumns: ColumnCategoryConfig = {
  [DatalabTableColumnCategory.MY_PARTICIPATION]: setColumnsCategory(
    [
      {
        ...dtc.fund_name,
        renderer: undefined, // renderer would target actual page
      },
      dtc.allocation,
      dtc.ioi_shares,
      dtc.fill_rate,
      dtc.allocation_pct,
      {
        ...dtc.allocation_investment,
        summaryConfig: [sumField, meanField, medianField, minField, maxField],
      },
      {
        ...dtc.sales_concession,
        summaryConfig: [meanField, medianField, minField, maxField],
      },
      {
        ...dtc.return_open,
        label: 'Return Open',
        summaryConfig: [sumField, meanField, medianField, minField, maxField],
        renderer: v => <PerformanceCurrency value={v} />,
        fixedMinWidth: undefined,
      },
      dtc.return_day1,
      dtc.return_day3,
      dtc.return_day7,
    ],
    DatalabTableColumnCategory.MY_PARTICIPATION
  ),
  [DatalabTableColumnCategory.ISSUER]: categorizedMasterColumns.ISSUER,
  [DatalabTableColumnCategory.OFFERING_TERMS]: categorizedMasterColumns.OFFERING_TERMS,
  [DatalabTableColumnCategory.OWNERSHIP]: categorizedMasterColumns.OWNERSHIP,
  [DatalabTableColumnCategory.PERFORMANCE]: categorizedMasterColumns.PERFORMANCE,
  [DatalabTableColumnCategory.PRICING_DISCOUNT]: categorizedMasterColumns.PRICING_DISCOUNT,
  [DatalabTableColumnCategory.TIMING]: categorizedMasterColumns.TIMING,
  [DatalabTableColumnCategory.UNDERWRITING]: categorizedMasterColumns.UNDERWRITING,
};
